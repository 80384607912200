import * as React from 'react';

import { theme } from '../../../../styles/2020/theme';

import { Clamp } from '../../layout/Clamp';
import { Column } from '../../layout/Column';
import { FluidGrid } from '../../layout/FluidGrid';

import { H3 } from '../Primitives';
import { Testimonial } from '../Testimonial';

type TestimonialGroupProps = {
  title: string;
  image: React.ReactNode;
  quotes: React.ComponentProps<typeof Testimonial>[];
};

export function TestimonialGroup({ title, image, quotes }: TestimonialGroupProps) {
  return (
    <Column space={theme.space.m}>
      <H3>{title}</H3>

      <FluidGrid min="300px" max="500px" space={theme.space.m} justify="start">
        <Clamp max="150px" style={{ marginTop: '-1rem' }}>
          {image}
        </Clamp>

        {quotes.map((quote) => (
          <Testimonial key={quote.author} {...quote} />
        ))}
      </FluidGrid>
    </Column>
  );
}
