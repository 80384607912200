import * as React from 'react';

import { theme } from '../../../../styles/2020/theme';

import { ButtonLink } from '../../common/ButtonLink';
import { Text } from '../../common/Primitives';
import { Clamp } from '../../layout/Clamp';
import { Column } from '../../layout/Column';
import { Switcher } from '../../layout/Switcher';

type CaseStudyProps = {
  title: string;
  text: string;
  image: React.ReactNode;
  url: string;
};

export function CaseStudy({ title, text, image, url }: CaseStudyProps) {
  return (
    <Switcher threshold="450px" space={theme.space.sToM} align="center">
      <Clamp max="180px">{image}</Clamp>

      <Clamp max="500px">
        <Column alignSelf="center">
          <Column space={theme.space.xxxs}>
            <Text as="h3" size="m" semiBold>
              {title}
            </Text>
            <Text size="s">{text}</Text>
          </Column>

          <ButtonLink url={url} openInNewTab>
            Learn more →
          </ButtonLink>
        </Column>
      </Clamp>
    </Switcher>
  );
}
